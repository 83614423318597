export type TSendTaskMakeAnAppointment = {
  message?: string;
  conversationId: string;
  tags?: string[];
  actorId?: string;
};

export type DiagnosisForm = {
  name: string;
  esbId: string;
};

export type TestForm = {
  name: string;
  id: string;
};

export type TSendTaskAddOnTestRequest = {
  message: string;
  conversationId: string;
  channels?: Tags;
  originalOrderNumber: string;
  tests: TestForm[];
  primaryDiagnosis?: DiagnosisForm;
  secondariesDiagnoses: DiagnosisForm[];
  orderingProvider: string;
  fasting: string;
};

export type Tags = 'billing' | 'clinical' | 'clerical';

export type TNewStatus = {
  assignmentId: string;
  conversationId: string;
  status: AssignmentStatus;
};

export enum Fasting {
  NotProvided = 'NotProvided',
  Fasting = 'Fasting',
  Nonfasting = 'Nonfasting',
}

export enum FastingVisible {
  'NotProvided' = 'Not Provided',
  'Fasting' = 'Fasting',
  'Nonfasting' = 'Nonfasting',
}

export enum AssignmentStatus {
  'Incomplete' = 'Incomplete',
  'Completed' = 'Completed',
  'Cancelled' = 'Cancelled',
}

export enum TAssignment {
  AddonTests = 'AddonTests',
  MakeAnAppointment = 'MakeAnAppointment',
  RejectionReasonSolving = 'RejectionReasonSolving',
  NoticeOfCriticalValues = 'NoticeOfCriticalValues',
}
