import { Typography, Stack, IconButton } from '@mui/material';
import { ReactComponent as TrashIcon } from 'src/icons/trash.svg';
import { ReactComponent as ArrowUp } from 'src/icons/arrow-up.svg';
import { Tooltip } from './Tooltip';
import { FC } from 'react';
import { DiagnosisForm } from 'src/api/assignments/models';

interface DiagnosisItemProps {
  diagnosis?: DiagnosisForm;
  makePrimary?: (value: DiagnosisForm) => void;
  deleteDiagnosis?: (value: DiagnosisForm) => void;
  marginBottom: string;
}

export const DiagnosisItem: FC<DiagnosisItemProps> = ({ diagnosis, makePrimary, deleteDiagnosis, marginBottom }) => {
  if (!diagnosis?.name) return null;
  const code = diagnosis.name?.split(' ')[0];
  const name = diagnosis.name?.split(' ').slice(1).join(' ');

  return (
    <Stack gap="12px" marginBottom={marginBottom}>
      <Stack direction="row" gap="12px" alignItems="center" justifyContent="space-between">
        <Stack direction="row" gap="12px" alignItems="center">
          <Typography fontWeight={600} fontSize="14px" color="grey.700">
            {code}
          </Typography>
          <Typography variant="label14Medium" color="grey.700">
            {name}
          </Typography>
        </Stack>
        <Stack direction="row">
          {makePrimary && (
            <Tooltip title="Make Primary" placement="top" arrow>
              <IconButton sx={{ padding: '0' }} onClick={() => makePrimary(diagnosis)}>
                <ArrowUp />
              </IconButton>
            </Tooltip>
          )}
          <IconButton sx={{ padding: '0' }} onClick={() => deleteDiagnosis && deleteDiagnosis(diagnosis)}>
            <TrashIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
