import React from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { getOrderById, getOrders, getTests, getProvidersByLab } from 'src/api/portal/portal';
import { filter } from './consts';
import { TDataForm } from './TestRequest';

export const getOrdersAction = ({ setOrdersOption, search, labCode }: any) => {
  if (setOrdersOption) {
    void getOrders({
      ...filter,
      search: undefined,
      laboratoryId: labCode,
      numbers: search?.search ? [+search.search] : undefined,
      statuses: ['InProgress', 'Created', 'Collected', 'Completed', 'InTransit'],
    })
      .then(result => {
        return setOrdersOption(
          result.data.orderItems.map(order => ({
            label: order.orderId,
            value: order.orderId,
          })),
        );
      })
      .catch(_error => console.log(_error));
  }
};

export const getTestsAction = ({
  setTests,
  providerId,
  orderId,
}: {
  setTests: React.Dispatch<React.SetStateAction<ISelectOption<string>[]>>;
  providerId: string;
  orderId: string;
}) => {
  if (setTests) {
    void getTests(orderId, providerId).then(result =>
      setTests(result.data.productItems.map(test => ({ label: test.name, value: test.id }))),
    );
  }
};

export const getOrderInformation = ({
  setLabCodeInOrder,
  setPatient,
  setValue,
  setOrderingProviderInOrder,
  orderId,
  setOrderIdNumber,
}: {
  setLabCodeInOrder?: React.Dispatch<React.SetStateAction<string>>;
  setPatient?: React.Dispatch<React.SetStateAction<{ name: string; patientId: string }>>;
  setValue?: UseFormSetValue<TDataForm>;
  setOrderingProviderInOrder?: React.Dispatch<React.SetStateAction<string>>;
  orderId: string;
  setOrderIdNumber: (val: string) => void;
}) => {
  if (orderId && setPatient && setValue && setLabCodeInOrder && setOrderingProviderInOrder) {
    void getOrderById(orderId).then(result => {
      const order = result.data;
      setOrderIdNumber(order.id);
      const patientInformation = order.patientSection?.patientForm;
      setPatient({
        name: patientInformation
          ? `${patientInformation.firstName} ${patientInformation.lastName}, ID ${patientInformation.patientIdentifier}`
          : '',
        patientId: patientInformation.patientId,
      });
      setOrderingProviderInOrder(order.productsSection.providerId);
      setValue('billType', order.productsSection.billingType);
      return setLabCodeInOrder(order.productsSection.labCode);
    });
  }
};

export const getProviders = ({ setProviders, search, labCodeInOrder }: any) => {
  if (setProviders) {
    void getProvidersByLab({ laboratoryId: labCodeInOrder, search: search || '' }).then(result =>
      setProviders(
        result.data.map((user: any) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        })),
      ),
    );
  }
};

export const changeDataForSend = (data: TDataForm, activeChat: string) => {
  const {
    primaryDiagnosis,
    secondaryDiagnoses,
    originalOrderNumber,
    message,
    tests,
    orderingProvider,
    billType,
    fasting,
  } = data;
  const task = {
    message,
    originalOrderNumber: (originalOrderNumber as ISelectOption<string>).label,
    tests: tests,
    primaryDiagnosis,
    secondariesDiagnoses: secondaryDiagnoses,
    orderingProvider: (orderingProvider as ISelectOption<string>).label,
    conversationId: activeChat,
    billType,
    tags: ['Medical'],
    fasting,
  };
  return task;
};
